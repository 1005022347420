var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { getWalletsWithExchanges } from '@entities/exchange';
import { TitleSecondary } from '@src/components/styled/Typography';
import { useLazyGetProfilePortfoliosQuery } from '@shared/api/portfolio';
import { useAppSelector } from '@app/store/Hooks';
import { useGetSupportedWalletTypesQuery, useLazyGetExchangeAccountsQuery, useLazyGetWalletsByExchangeIdQuery } from '@shared/api/exchange';
import useThunkDispatch from '@src/shared/lib/hooks/useThunkDispatch';
import { ContainerColumn, SurfaceColumn, alignToCenter } from '@src/components/styled';
import { useProfileOutletContext } from '@features/profile/profile-info';
import useProfileNavigation from '@features/profile/profile-navigation';
import { mapSupportedWalletsDict } from '@entities/wallet';
import PortfoliosEmptyView from './components/PortfoliosEmptyView';
import TraderPortfoliosList from './components/TraderPortfoliosList';
import ClientPortfoliosList from './components/ClientPortfoliosList';
import { getPortfolioTabs, matchPathToPortfolioTab } from '../lib';
const ProgressErrorContainer = alignToCenter(ContainerColumn);
const Content = styled(ContainerColumn)((props) => ({
    gap: props.theme.spacing_sizes.l,
}));
const EmptyViewContainer = styled(SurfaceColumn)(props => ({
    boxSizing: 'border-box',
    height: 'auto',
    boxShadow: `0px 1px 3px 0px ${props.theme.customColors.surface.outline}`,
    border: 'none',
    borderRadius: 12,
    padding: `${props.theme.spacing_sizes.m * 3}px ${props.theme.spacing_sizes.l}px 
              ${props.theme.spacing_sizes.m * 3}px ${props.theme.spacing_sizes.l}px`,
}));
const PortfoliosTab = () => {
    var _a;
    const { portfolioId: portfolioIdParam } = useParams();
    const { profileId, nickname, profileType, isMyProfile = false, } = useProfileOutletContext();
    const { language } = useAppSelector(state => state.app);
    const { walletsForPortfolioTab, exchangesList } = useAppSelector(state => state.exchange);
    const { pathname: locationPathname } = useLocation();
    const { navigateToProfileTab, navigateToPortfolioTab, } = useProfileNavigation();
    const dispatch = useThunkDispatch();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [_, { data: portfoliosList, isFetching: isFetchingPortfolios, isSuccess, }] = useLazyGetProfilePortfoliosQuery();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [__, { status: exchangesStatus, isError: isExchangesError, isFetching: isFetchingExchanges, isSuccess: isExchangesSuccess, }] = useLazyGetExchangeAccountsQuery();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [___, { status: walletsStatus, isFetching: isFetchingWallets, isSuccess: isWalletsSuccess, isError: isWalletsError, }] = useLazyGetWalletsByExchangeIdQuery();
    const { data: { data: supportedWalletsDict } } = useGetSupportedWalletTypesQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: mapSupportedWalletsDict(data) }));
        },
    });
    const portfolioId = portfolioIdParam || undefined;
    const arePortfoliosFetched = !isFetchingPortfolios && isSuccess;
    const areExchangesFetched = !exchangesList.isLoading && exchangesList.list !== null;
    const areWalletsFetched = !walletsForPortfolioTab.isLoading &&
        walletsForPortfolioTab.list !== null;
    const areWalletsEmpty = areWalletsFetched &&
        walletsForPortfolioTab.list && walletsForPortfolioTab.list.length === 0;
    const areExchangesEmpty = areExchangesFetched &&
        exchangesList.list && exchangesList.list.length === 0;
    const arePortfoliosEmpty = areWalletsFetched && portfoliosList && (portfoliosList === null || portfoliosList === void 0 ? void 0 : portfoliosList.length) === 0;
    // TODO: discuss
    const isFetching = isFetchingExchanges || isFetchingWallets;
    const isError = isExchangesError || isWalletsError;
    const onPortfolioSelect = (selectedPortfolioId) => {
        if (selectedPortfolioId === portfolioId) {
            navigateToProfileTab('portfolios', { params: { nickname } });
        }
        else {
            navigateToPortfolioTab('information', {
                params: {
                    nickname,
                    portfolioId: String(selectedPortfolioId),
                },
            });
        }
    };
    const portfolioTabs = getPortfolioTabs(language);
    const currentTab = useMemo(() => matchPathToPortfolioTab(locationPathname), [locationPathname]);
    const onTabChange = (selectedTab) => {
        if (!portfolioId) {
            return;
        }
        navigateToPortfolioTab(selectedTab, {
            params: {
                nickname,
                portfolioId: String(portfolioId),
            },
        });
    };
    useEffect(() => {
        if (exchangesList.list === null && walletsForPortfolioTab.list === null) {
            dispatch(getWalletsWithExchanges());
        }
    }, [exchangesList, walletsForPortfolioTab]);
    return (_jsx(ContainerColumn, { children: _jsxs(_Fragment, { children: [_jsx(Content, { children: (areExchangesEmpty || areWalletsEmpty) && (_jsx(EmptyViewContainer, { children: _jsx(PortfoliosEmptyView, { profileType: profileType, isMyProfile: isMyProfile, haveExchanges: Boolean(exchangesList.list && ((_a = exchangesList.list) === null || _a === void 0 ? void 0 : _a.length) > 0) }) })) }), arePortfoliosFetched &&
                    profileType === 'TRADER' &&
                    (_jsx(TraderPortfoliosList, { nickname: nickname, portfolioId: portfolioId, portfoliosList: portfoliosList || [], portfolioTabs: portfolioTabs, onPortfolioSelect: onPortfolioSelect, profileId: profileId, currentTab: currentTab, onTabChange: onTabChange, selectedPortfolio: portfolioId })), areWalletsFetched &&
                    areExchangesFetched &&
                    profileType === 'CLIENT' &&
                    (_jsx(ClientPortfoliosList, { walletsList: walletsForPortfolioTab.list || [], portfoliosList: portfoliosList || [], supportedWalletsDict: supportedWalletsDict })), !isFetching &&
                    isError &&
                    (_jsx(ProgressErrorContainer, { children: _jsx(TitleSecondary, { children: "error" }) }))] }) }));
};
export default PortfoliosTab;
